import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import "@styles/base/pages/page-misc.scss";
import Logo from "../assets/images/logo/logo.png";
import { NavRoutes } from "../common";

const NotAuthorized = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white" style={{ height: "100vh" }}>
      <div className="w-100 ms-1 pt-2">
        <img src={Logo} alt="AnatomicIron" />
      </div>
      <div className="misc">
        <div className="misc-inner p-2 p-sm-3">
          <div className="w-100 text-center">
            <h2 className="mb-1">You are not authorized! 🔐</h2>
            <Button
              to={NavRoutes.dashboard}
              onClick={() => {
                navigate(NavRoutes.dashboard);
                window.location.reload();
              }}
              color="primary"
              className="btn-sm-block mb-2"
            >
              Back to home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotAuthorized;
