import { useContext } from 'react';
import { Network, Urls } from '../apiConfiguration';
import { NavRoutes } from '../common';
import { showErrorMessage } from '../components';
import { AuthContext } from '../context';
import { getScreenPermissions } from '../utility/Utils';
import useLoader from './useLoader';
import useSelectedCompany from './useSelectedCompany';

const useAuth = () => {
  const { token, setToken, currentUser, setCurrentUser } = useContext(AuthContext);
  const { setCompany } = useSelectedCompany();

  const { setLoading } = useLoader();

  const Login = (token, user) => {
    localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, token);
    localStorage.setItem(process.env.REACT_APP_ANATOMIC_USER_KEY, JSON.stringify(user));
    setToken(token);
    setCurrentUser(user);
    setCompany(user.company_id);
  };
  const Logout = () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    localStorage.removeItem(process.env.REACT_APP_ANATOMIC_USER_KEY);
    setToken(null);
    setCurrentUser(null);
  };

  const saveUser = (user) => {
    localStorage.setItem(process.env.REACT_APP_ANATOMIC_USER_KEY, JSON.stringify(user));
    setCurrentUser(user);
  };

  const restoreData = async () => {
    const t = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
    const u = localStorage.getItem(process.env.REACT_APP_ANATOMIC_USER_KEY);

    if (t) {
      setToken(t);
      setLoading(true);
      if (u.is_admin) {
        setCurrentUser(u);
      } else {
        const response = await Network.get(Urls.getLoggedInUser);
        if (!response.ok) {
          return showErrorMessage(response.data.message);
        }

        const { user } = response.data;

        let ability = user.permissions.map((item) => {
          return item.name;
        });

        ability = ability.map((item) => {
          return {
            action: 'manage',
            subject: item,
          };
        });

        const hasValuePosition = ability.some((obj) => obj['subject'] === 'positions');

        if (hasValuePosition) {
          ability = [
            ...ability,
            { action: 'manage', subject: 'colorCode' },
            { action: 'manage', subject: 'task' },
          ];
        }

        const changeCompany = !getScreenPermissions(user.permissions, 'companies').includes('show');
        setLoading(false);
        localStorage.removeItem(process.env.REACT_APP_ANATOMIC_USER_KEY);
        Login(t, { ...user, ability, changeCompany });
      }
    }
  };

  return { Login, token, user: currentUser, Logout, saveUser, restoreData };
};

export default useAuth;
