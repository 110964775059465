import { useState } from 'react';
import { Table, Input } from 'reactstrap';
import { Eye, Trash2 } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { useSelectedCompany, useLoader } from '../../hooks';
import { NavRoutes } from '../../common';
import { showSuccessMessage, showErrorMessage } from '../toast';
import { ConfirmationModal } from '../../components';
import { Network, Urls } from '../../apiConfiguration';
import { ChevronUp, ChevronDown } from 'react-feather';
import './style.css';

const columns = [
  { label: 'Project Code', showSort: true, column_key: 'project_code' },
  { label: 'Customers', showSort: false, column_key: 'customers' },
  { label: 'Project Name', showSort: true, column_key: 'project_name' },
  { label: 'Budget Hours', showSort: false, column_key: 'budget_hours' },
  {
    label: 'Status',
    showSort: false,
    column_key: 'status',
  },
  { label: 'Completed Hours', showSort: false, column_key: 'completed_hours' },
  { label: 'Actions', showSort: false, column_key: 'actions' },
];

const ScrollTable = ({
  data,
  hasMore,
  isLoading,
  setCurrentPage,
  permissions,
  setApiCall,
  handleSort,
}) => {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [project, setProject] = useState(null);
  const [activeModal, setActiveModal] = useState(false);
  const { setLoading } = useLoader();
  const { company } = useSelectedCompany();
  const [status, setStatus] = useState(null);
  const [projectId, setProjectId] = useState(null);

  const handleScroll = (event) => {
    const element = event.target;
    const scrollPosition = element.scrollTop + element.clientHeight;
    const isCloseToBottom = scrollPosition >= element.scrollHeight - 20;

    if (isCloseToBottom) {
      if (!isLoading && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleProjectStatus = async () => {
    setLoading(true);
    const response = await Network.post(Urls.changeProjectStatus(company), {
      is_active: status ? 1 : 0,
      project_id: projectId,
    });
    if (!response.ok) return showErrorMessage(response.data.message);
    showSuccessMessage(response.data.message);
    setLoading(false);
    setApiCall((prev) => !prev);
  };

  const deleteProject = async () => {
    setLoading(true);
    const response = await Network.delete(`${Urls.manageProjects(company)}/${project.id}`);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    const length = data.length - 1;
    if (length == 0) setCurrentPage(currentPage - 1);
    showSuccessMessage('Project has been deleted');
    setDeleteModal(false);
    setApiCall((prev) => !prev);
  };

  const Sort = () => {
    return (
      <div className='ms-1'>
        <div>
          <ChevronUp size={10} />
        </div>
        <div>
          <ChevronDown size={10} />
        </div>
      </div>
    );
  };

  return (
    <>
      <ConfirmationModal
        btnText={'Delete'}
        description='Are you sure you want to delete this project?'
        fn={deleteProject}
        mode='Delete'
        showModal={deleteModal}
        setShowModal={setDeleteModal}
      />
      <ConfirmationModal
        description='Are you sure you want to active the project?'
        mode='Active'
        btnText='Active'
        fn={handleProjectStatus}
        showModal={activeModal}
        setShowModal={setActiveModal}
      />
      <div className='table-container' onScroll={handleScroll}>
        <Table responsive>
          <thead>
            <tr>
              {columns.map(({ label, showSort, column_key }, index) => (
                <th key={index}>
                  <div className='d-flex'>
                    <div>{label}</div>
                    {showSort ? (
                      <div onClick={() => handleSort(column_key)}>
                        <Sort />
                      </div>
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((project) => (
              <tr>
                <td>{project?.project_code}</td>
                <td>{project?.client_name}</td>
                <td>{project?.project_name}</td>
                <td>{project?.miscellaneous_budget + project?.budget_hours}</td>
                <td>
                  <div className='form-switch form-check-success ms-1'>
                    <Input
                      type='switch'
                      id='switch-success'
                      name='is_active'
                      checked={project?.is_active}
                      onChange={(e) => {
                        setStatus(e.target.checked);
                        setProjectId(project.id);
                        setActiveModal(true);
                      }}
                    />
                  </div>
                </td>
                <td>{project?.completed_hours}</td>
                <td>
                  <div className='d-flex justify-content-evenly align-content-center'>
                    <Eye
                      onClick={() =>
                        navigate(`${NavRoutes.projectDetail}/${project.id}`, {
                          state: {
                            editable: permissions.find((item) => item === 'edit') ? true : false,
                          },
                        })
                      }
                      className='cursor-pointer'
                      size={18}
                    />
                    {!!permissions.find((item) => item === 'delete') && (
                      <Trash2
                        color='red'
                        className='cursor-pointer ms-1'
                        size={18}
                        onClick={() => {
                          setProject(project);
                          setDeleteModal(true);
                        }}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ScrollTable;
