import { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Label, Button, Input } from 'reactstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import { Network, Urls } from '../../../apiConfiguration';
import { useLoader, useSelectedCompany, useAuth } from '../../../hooks';
import { showErrorMessage, showSuccessMessage } from '../../../components';
import { useFormik } from 'formik';
import { DataTransformer, validTimeEntryCheck } from '../../../utility/Utils';

const TaskModal = ({ open, setOpen, zones, projectStatus, setApiCall }) => {
  const { setLoading } = useLoader();
  const { company } = useSelectedCompany();
  const { user } = useAuth();
  const [workCategories, setWorkCategories] = useState([]);
  const [employeeLocation, setEmployeeLocation] = useState();
  const [workCodes, setWorkCodes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [officeLocations, setOfficeLocations] = useState([]);
  const [timeError,setTimeError] = useState(false);


  const getCategories = async () => {
    setLoading(true);

    const response = await Network.get(Urls.manageWorkCategories(company));

    setLoading(false);

    if (!response.ok) return showErrorMessage(response.data.message);

    const { work_categories } = response.data;
    setWorkCategories(DataTransformer(work_categories, 'work_category_name', 'id').filter((category) => category.value === 5 || category.value ===6 ));
  };

  const getWorkCodes = async (id) => {
    setLoading(true);

    const response = await Network.get(Urls.workCodesByWorkcategories(company) + id);

    setLoading(false);

    if (!response.ok) return showErrorMessage(response.data.message);

    const { workcodes } = response.data;

    setWorkCodes(DataTransformer(workcodes, 'work_label', 'id'));
  };

  const getEmployee = async (locationId) => {
    setLoading(true);
    const response = await Network.post(Urls.getEmployeeByRole,{
        role: [14,13],
        location_id: locationId ?? user?.office_location_id,
    });
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setEmployees(DataTransformer(response.data, 'name', 'id'));
  };



  const getProject = async () => {
    setLoading(true);
    const response = await Network.post(Urls.getProjectWithoutPagination(company), {
      team_id: '',
      client_id: '',
    });
    setLoading(false);
    setProjects(DataTransformer(response.data, 'project_name', 'id'));
  };

  const getOfficeLocation = async () => {
    const response = await Network.get(Urls.getOfficeLocations);
    if (!response.ok) return showErrorMessage(response.data.message);
    setOfficeLocations(DataTransformer(response.data.office_locations, 'name', 'id'));
  };

  useEffect(() => {
    selectedCategory && getWorkCodes(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    employeeLocation && getEmployee(employeeLocation);
  }, [employeeLocation]);

  useEffect(() => {
    getCategories();
    getEmployee();
    getProject();
    getOfficeLocation();
  }, []);

  const initialValues = {
    project_id: '',
    client_id: '',
    editor: '',
    location_id: user?.office_location_id,
    zone_number: '',
    status: 0,
    coordinator_id: 148,
    work_code_id: '',
    time: '',
  };

  const onSubmit = async (data) => {
    if(timeError) return;
    setLoading(true);
    const response = await Network.post(Urls.createTask(company), data);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    showSuccessMessage('Task added successfully');
    setApiCall((prev) => !prev);
    setOpen(false);
  };

  const { values, handleSubmit, touched, handleChange, handleBlur,errors,setErrors } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object().shape({
      project_id: Yup.string().required('Project is required'),
      // client_id: Yup.number().required('Exam Score is required'),
      editor_id: Yup.string().required('Editor is required'),
      location_id: Yup.string().required('Location is required'),
      zone_number: Yup.string().required('Zone number is required'),
      status: Yup.string().required('Status is required'),
      work_code_id: Yup.string().required('Work code is required'),
      time: Yup.string().required('Time is required'),
    }),
  });

  const checkValue = (e) => {
    if(!validTimeEntryCheck(values.time)) {
      setErrors({"time": `Please enter time in 15 minutes interval`})
      setTimeError(true);
      return
    }
    handleBlur(e);
  }

  const ErrorMessage = ({ name }) => {
    if (!touched[name]&&!errors[name]) return null;
    return <div className='text-danger fst-italic fw-bold'>{errors[name]}</div>;
  };

  console.log(errors);
  return (
    <Modal isOpen={open} toggle={() => setOpen(!open)}>
      <ModalHeader className='bg-white' toggle={() => setOpen(!open)}>
        Add Task
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Locations</Label>
            <Select
              placeholder='Location'
              value={officeLocations.filter((i) => i.value === values.location_id)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'location_id',
                    value,
                  },
                };
                handleChange(event);
                setEmployeeLocation(value);
              }}
              options={officeLocations}
            />
            <ErrorMessage name='location_id' />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Time</Label>
            <InputMask
                onBlur={(e)=> {
                  checkValue(e);
                }}
              placeholder='00:00'
              mask='99:99'
              name='time'
              maskChar={':'}
              value={values.time}
              onChange={(e) => {
                const inputTime = e.target.value;
                const [hours, minutes] = inputTime.split(':').map(Number);
                let convertedHours = hours;
                let convertedMinutes = minutes;

                // Check if minutes exceed 59
                if (minutes > 59) {
                  const extraHours = Math.floor(minutes / 60);
                  convertedHours += extraHours;
                  convertedMinutes = minutes % 60;
                }
                const formattedTime = `${convertedHours
                  .toString()
                  .padStart(2, '0')}:${convertedMinutes.toString().padStart(2, '0')}`;
                handleChange({
                  target: {
                    name: 'time',
                    value: formattedTime,
                  },
                });
              }}
            >
              {(inputProps) => <Input name='time' {...inputProps} />}
            </InputMask>
            <ErrorMessage name='time' />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Projects</Label>
            <Select
              placeholder='Project'
              value={projects.filter((i) => i.value === values.project_id)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'project_id',
                    value,
                  },
                };
                handleChange(event);
              }}
              options={projects}
            />
            <ErrorMessage name='project_id' />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Status</Label>
            <Select
              isDisabled
              name='status'
              placeholder='Status'
              value={projectStatus.filter((i) => i.value === values.status)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'status',
                    value,
                  },
                };
                handleChange(event);
              }}
              options={projectStatus}
            />
            <ErrorMessage name='status' />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Zone</Label>
            <Select
              placeholder='Zone'
              value={zones.filter((i) => i.value === values.zone_number)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'zone_number',
                    value,
                  },
                };
                handleChange(event);
              }}
              options={zones}
            />
            <ErrorMessage name='zone_number' />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Work Categories</Label>
            <Select
              placeholder='Categories'
              value={workCategories.filter((i) => i.value === selectedCategory)}
              onChange={({ value }) => {
                setSelectedCategory(value);
              }}
              options={workCategories}
            />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Work Codes</Label>
            <Select
              isDisabled={!selectedCategory}
              placeholder='Work Codes'
              value={workCodes.filter((i) => i.value === values.work_code_id)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'work_code_id',
                    value,
                  },
                };
                handleChange(event);
              }}
              options={workCodes}
            />
            <ErrorMessage name='work_code_id' />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Editor</Label>
            <Select
              placeholder='Editor'
              value={employees.filter((i) => i.value === values.editor_id)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'editor_id',
                    value,
                  },
                };
                handleChange(event);
                setEmployeeLocation(value);
              }}
              options={employees}
            />
            <ErrorMessage name='editor_id' />
          </Col>
        </Row>
        <Button className='mt-2' color='primary' onClick={() => handleSubmit()}>
          Add Task
        </Button>
      </ModalBody>
    </Modal>
  );
};
export default TaskModal;
