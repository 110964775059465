import { Fragment, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Bell } from 'react-feather';
import {
  Button,
  Badge,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import { useLoader, useAuth } from '../../../../hooks';
import { Network, Urls } from '../../../../apiConfiguration';
import { showErrorMessage } from '../../../../components';

const NotificationDropdown = () => {
  const [notifications, setNotifications] = useState([]);
  const { setLoading } = useLoader();
  const { token, user } = useAuth();
  const unRead = notifications.filter((item) => !item.read_at).length;

  useEffect(() => {
    if (token && !user.is_admin) getNotifications();
  }, [token]);

  const getNotifications = async () => {
    setLoading(true);
    const response = await Network.get(Urls.getNotifications);
    setLoading(false);

    if (!response.ok) return showErrorMessage(response.data.message);
    setNotifications(response.data.notifications);
  };

  const readNotification = async (id) => {
    setNotifications(
      notifications.map((item) => {
        if (item.notification_id === id) {
          return { ...item, read_at: true };
        }
        return item;
      })
    );

    const response = await Network.post(Urls.markSingleNotificationRead, {
      notification_id: id,
    });

    if (!response.ok) {
      return setNotifications(
        notifications.map((item) => {
          if (item.notification_id === id) {
            return { ...item, read_at: false };
          }
          return item;
        })
      );
    }
  };

  const readAllNotifications = async () => {
    const oldNotifications = [...notifications];

    setNotifications(
      notifications.map((item) => {
        if (!item.read_at) {
          return { ...item, read_at: true };
        }
        return item;
      })
    );

    const response = await Network.get(Urls.markAllNotificationsRead);
    if (!response.ok) {
      return setNotifications(oldNotifications);
    }
  };

  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component='li'
        className='media-list scrollable-container'
        options={{
          wheelPropagation: false,
        }}
      >
        {notifications.map((item, index) => {
          return (
            <div
              key={index}
              className='d-flex flex-column '
              onClick={(e) => {
                if (!item.switch) {
                  e.preventDefault();
                }
              }}
            >
              <div
                onClick={() => {
                  !item.read_at ? readNotification(item.notification_id) : null;
                }}
                className={`list-item d-flex align-items-center cursor-pointer ${
                  !item.read_at ? 'cursor-pointer' : ''
                }`}
              >
                <Fragment>
                  {!item.read_at ? (
                    <div
                      className='rounded-circle bg-primary me-1'
                      style={{ width: '10px', height: '10px' }}
                    ></div>
                  ) : null}

                  <div className='list-item-body flex-grow-1'>{item.data.message}</div>
                </Fragment>
              </div>
            </div>
          );
        })}
      </PerfectScrollbar>
    );
  };
  /*eslint-enable */

  const clearAllNotifications = async () => {
    const response = await Network.get(Urls.clearNotifications);
    if (!response.ok) return showErrorMessage(response.data.message);
  
    setNotifications([]);
  };

  return (
    <UncontrolledDropdown tag='li' className='dropdown-notification nav-item me-25'>
      <DropdownToggle tag='a' className='nav-link' href='/' onClick={(e) => e.preventDefault()}>
        <Bell size={21} />
        <Badge pill color='danger' className='badge-up'>
          {unRead}
        </Badge>
      </DropdownToggle>
      <DropdownMenu end tag='ul' className='dropdown-menu-media mt-0'>
        <li className='dropdown-menu-header'>
          <DropdownItem tag='div' header>
            <div className='d-flex align-items-center justify-content-between'>
              <h4>Notifications</h4>
              {!!notifications.length && (
                <Badge
                  className='cursor-pointer'
                  color='light-primary'
                  onClick={() => clearAllNotifications()}
                >
                  Clear All
                </Badge>
              )}
            </div>
          </DropdownItem>
        </li>
        {renderNotificationItems()}
        {!!notifications.length ? (
          <li className='dropdown-menu-footer'>
            <Button color='primary' block onClick={readAllNotifications}>
              Read all notifications
            </Button>
          </li>
        ) : (
          <div className='text-center h5 my-1 text-primary'>No Notifications</div>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationDropdown;
