import { create } from 'apisauce';
import { showErrorMessage } from '../components';

const baseURL = process.env.REACT_APP_BACKEND_URL;


const client = create({
  baseURL,
});

client.addAsyncRequestTransform(async (request) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  if (!token) {
    return;
  }

  request.headers['Authorization'] = `Bearer ${token}`;
});

export const config = async () => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  };
};

export const authConfig = async (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  };
};

export const multipartConfig = async () => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'multipart/form-data',
    },
  };
};
export const blobConfig = async () => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: '*/*',
      responseType: 'arraybuffer',
    },
  };
};

const responseMonitor = (response) => {
  if (response.status === 401) {
    localStorage.clear();
    showErrorMessage('NotAuthorized');
    window.location.href = '/';
  }
};

client.addMonitor(responseMonitor);

export default client;
