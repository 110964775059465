import { DefaultRoute } from '../router/routes';
import dayjs from 'dayjs';
import { dayNames } from '../common/Data';
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */

export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return DefaultRoute;
  if (userRole === 'client') return '/access-control';
  return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

export const DataTransformer = (data, label, value) => {
  const newarray = data?.map((element) => {
    return {
      label: element[label],
      value: element[value],
    };
  });
  return newarray;
};

export const getYearsTillCurrent = () => {
  const currentYear = dayjs().year();
  const startYear = 2000;
  let yearsArray = [];

  for (let index = startYear; index <= currentYear; index++) {
    yearsArray.push(index);
  }

  yearsArray.sort((a, b) => b - a);

  return yearsArray.map((item) => {
    return { label: item, value: item };
  });
};

export const createZones = (start, end) => {
  let array = [];
  for (let index = start; index <= end; index++) {
    array.push({ label: index, value: index });
  }
  return array;
};

export const createTimeList = (point = 0) => {
  let time = point;
  let timeArray = [];
  while (time < 24) {
    for (let i = 0; i !== 60; i = i + 15) {
      let value = `${time.toString().length !== 2 ? `0${time}` : time}:${
        i.toString().length !== 2 ? `0${i}` : i
      }`;

      timeArray.push({
        label: value,
        value,
      });
    }
    time++;
  }
  return timeArray;
};

export const convertFromStringToTimeFormat = (value) => {
  const [hours, minutes] = value.split(':');
  const date = new Date();
  return dayjs(new Date(date.getFullYear(), date.getMonth(), date.getDate(), +hours, +minutes, 0));
};

//For theremometer

export const ModifyResponse = (data) => {
  const dataArray = [];
  for (const key in data.targetHours) {
    const targetHoursArray = [];
    const actualHoursArray = [];
    for (let i = 0; i < data.number_of_zones; i++) {
      actualHoursArray.push({ actualTime: '-' });
    }
    let totalTargetTime = 0;
    let totalActualTime = 0;
    data?.targetHours[key].map((subdata, index) => {
      targetHoursArray.push(subdata);
      totalTargetTime += subdata.targetTime;
    });

    if (data.actualHours[key]) {
      data.actualHours[key].map((subdata, index) => {
        actualHoursArray[subdata.zone_number - 1] = subdata;
        totalActualTime += subdata.actualTime;
      });
    }

    const obj = {
      name: key,
      targetHoursArray,
      totalTargetTime,
      actualHoursArray,
      totalActualTime,
    };
    dataArray.push(obj);
  }

  return dataArray;
};

export const tableHeaderZone = (start, end) => {
  let array = [];
  for (let index = start; index <= end; index++) {
    array.push(index);
  }
  return array;
};

export const timeConvert = (t, flag = false) => {
  if (t == '-') return '-';
  if (t == null) return '00:00';
  let time = t.toString();
  let minutes = '00';
  if (time.includes('.')) {
    const value = time.split('.')[1];
    if (value == '25') minutes = '15';
    if (value == '50' || value == '5') minutes = '30';
    if (value == '75') minutes = '45';
  }

  let hours = time.split('.')[0];
  hours = hours.length !== 2 ? `0${hours}` : hours;
  if (flag) return `${hours} hours ${minutes} minutes`;
  return `${hours}:${minutes}`;
};

//Modify Period Report

export const modifyZoneReport = (data) => {
  let i = 1;
  const length = data.workingHours?.length;
  let key = '';
  let mode = '';
  let total = 0;
  let zones = parseInt(data.number_of_zones);
  let finalArray = [];
  let workHours = [];
  while (i !== length + 1) {
    workHours.push(data.workingHours[i - 1].working_hours);
    total += data?.workingHours[i - 1].working_hours;

    if (i % zones == 0) {
      key = data.workingHours[i - 1].work_category_name;
      mode = data.workingHours[i - 1].work_code_name;
      finalArray.push({ name: key, mode, workHours, total });
      workHours = [];
      total = 0;
    }

    i++;
  }

  return finalArray;
};

export const getScreenPermissions = (permissions, name) => {
  if (!permissions) {
    return [];
  }
  const p = permissions.find((item) => item.name === name);
  if (p) return p[name].map((item) => item.name.split(`-${name}`)[0]);
  return [];
};

export const createMonthDates = (limit = 31) => {
  let dates = [];

  for (let index = 1; index != limit + 1; index++) {
    dates.push({ label: index, value: index });
  }

  return dates;
};

export const createThermometerScale = (percentage, total) => {
  return ['200%', '180%', '170%', '160%', '140%', '120%', '100%', '80%', '60%', '40%', '20%', '0%'];

  // let scale = [];
  // let scaleValue = 0;
  // const increment = Math.ceil(percentage / total) * 10;

  // do {
  //   scale.push(`${scaleValue}%`);
  //   scaleValue += increment;
  // } while (scaleValue < percentage);

  // scale.push(`${scaleValue}%`);

  // return scale.reverse();
};

export const modifyProjectBudget = (projectBudget) => {
  const workingHours = projectBudget.work_categories?.map(({ work_category, work_codes }) => {
    let workCategory = [];
    for (let i = 0; i < work_codes.length; i++) {
      workCategory.push({
        name: work_codes[i],
        [work_codes[i]]: [],
      });
    }
    return {
      name: work_category,
      [work_category]: workCategory,
    };
  });

  // projectBudget.project_zone_budget?.map((block) => {
  //   block.block_zones?.map(({ work_code_name, work_category_name, target_hours }) => {
  //     const obj = workingHours.filter((item) => item.includes('Prelim'));
  //     console.log({ obj });
  //   });
  // });
};

const convertRoute = (route) => {
  if (route.includes('-')) {
    const newRoute = route.split('-');
    let finalRoute = '';
    newRoute.map((item) => {
      finalRoute = finalRoute + item;
    });

    return finalRoute.toLowerCase();
  } else if (route === 'customers') {
    return 'clients';
  }
  return route;
};

export const accessRoute = (user) => {
  const { href, origin } = window.location;
  let route = href.split(origin)[1];

  route = convertRoute(route.split('/')[1]);

  if (
    route === 'login' ||
    route === 'notauthorized' ||
    route === '' ||
    route === 'admin' ||
    route === 'error'
  )
    return;

  const hasAccess = user.ability.some((item) => item.subject.toLowerCase() == route);

  if (route === 'dashboard') {
    return;
  }

  if (!hasAccess) {
    window.location.href = '/not-authorized';
  }
};

export const getProjectManagerList = (data) => {
  const projectManagers = data.map((projectManager) => projectManager.project_manager);

  return projectManagers;
};

export const calculateDayTime = (data) => {
  let time_by_day = {
    Total: 0,
  };
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  for (let item = 0; item < data.length; item++) {
    let day = data[item].day;
    let start = convertFromStringToTimeFormat(data[item].start_time);
    let end = convertFromStringToTimeFormat(data[item].end_time);
    let time = end.diff(start, 'seconds') / 3600;

    if (day in time_by_day) {
      time_by_day[day] += time;
      time_by_day['Total'] += time;
    } else {
      time_by_day[day] = time;
      time_by_day['Total'] += time;
    }
  }
  const sortedKeys = Object.keys(time_by_day).sort(
    (a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
  );
  const sortedObj = {};

  sortedKeys.forEach((key) => {
    sortedObj[key] = time_by_day[key];
  });

  const totalTime = sortedObj.Total;
  delete sortedObj.Total;
  Object.assign(sortedObj, { Total: totalTime });
  return sortedObj;
};

export const transformTimeFactor = (timeFactor) => {
  if (timeFactor == 'Regular Time') return 'regular_time';
  if (timeFactor == 'Over Time') return 'over_time';
  if (timeFactor == 'Premium Time') return 'premium_time';
};

export const getWeeksList = (year, month) => {
  const days = new Date(year, month + 1, 0).getDate();

  const totalWeeks = Math.ceil(days / 7);
  let array = [];
  for (let index = 1; index <= totalWeeks; index++) {
    array.push({ label: index, value: index });
  }
  return array;
};

export const handleTime = (value) => {
  const [hours, minutes] = value.split(':');
  const date = new Date();
  return dayjs(new Date(date.getFullYear(), date.getMonth(), date.getDate(), +hours, +minutes, 0));
};

export const getDaysArray = function (year, month) {
  const monthIndex = month - 1; // 0..11 instead of 1..12

  const date = new Date(year, monthIndex, 1);
  const result = [];
  while (date.getMonth() == monthIndex) {
    result.push(date.getDate() + '-' + dayNames[date.getDay()]);
    date.setDate(date.getDate() + 1);
  }
  return result;
};

export const reactSelectCustomStyles = {
  // Add any custom styles you want to apply to the options menu
  menu: (provided, state) => ({
    ...provided,
    // Add your custom class name here
    zIndex: 2,
    className: 'custom-options-menu',
  }),
};

export const sumTime = (A) => {
  let regular_time = 0;
  let extra_time = 0;
  let premium_time = 0;
  let total_Time = 0;
  for (let i = 0; i < A.length; i++) {
    regular_time = regular_time + A[i]?.regularTime;
    extra_time = extra_time + A[i]?.extraTime;
    premium_time = premium_time + A[i]?.premiumTime;
    total_Time = total_Time + A[i]?.TotalTime;
  }

  return {
    project_name: 'Total',
    regularTime: regular_time,
    premiumTime: premium_time,
    extraTime: extra_time,
    TotalTime: total_Time,
  };
};

export const convertToHoursMinutes = (totalMinutes) => {
  // Calculate hours and remaining minutes
  const convertedHours = Math.floor(totalMinutes / 60);
  const convertedMinutes = totalMinutes % 60;

  // Format the result as "hours:minutes"
  const result = `${convertedHours.toString().padStart(2, '0')}:${convertedMinutes
    .toString()
    .padStart(2, '0')}`;

  return result;
};

export const sumSubTaskTime = (timeArray) => {
  let totalTime = 0;
  timeArray?.map((subTask) => {
    totalTime = totalTime + subTask?.totalTime;
  });
  return totalTime;
};

export const convertSubTaskTime = (t) => {
  let time = t?.toString();
  let minutes = '00';
  if (time?.includes('.')) {
    const value = time.split('.')[1];
    if (value == '25') minutes = '15';
    if (value == '50' || value == '5') minutes = '30';
    if (value == '75') minutes = '45';
  }

  let hours = time?.split('.')[0];
  hours = hours?.length !== 2 ? `0${hours}` : hours;

  return `${hours}:${minutes}`;
};

export const calculateEfficiency = (totalTime, actualTime) => {
  let [totalHours, totalMinutes] = totalTime.split(':').map(Number);
  let [actualHours, actualMinutes] = actualTime.split(':').map(Number);


  totalMinutes = totalHours * 60 + totalMinutes;
  actualMinutes = actualHours * 60 + actualMinutes;
  if(actualMinutes === 0) return 0;
  // Calculate efficiency
  const efficiency = (  totalMinutes/actualMinutes) * 100;

  return efficiency.toFixed(2); // return efficiency with 2 decimal places
};

export const validTimeEntryCheck = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const validMinutes = [0, 15, 30, 45];
  return validMinutes.includes(minutes);
}
