// ** React Imports
import { Fragment } from 'react';

// ** Custom Components
import NavbarUser from './NavbarUser';

// ** Third Party Components
import { Menu } from 'react-feather';

// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap';

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props;

  const handlePageName = () => {
    const name = window.location.pathname.split('/')[1].toUpperCase();
    if (name.includes('-')) {
      return name.split('-').join(' ');
    }
    if (name == 'EMPLOYEE') return 'EMPLOYEE RECORDS ';
    return name;
  };

  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <ul className='navbar-nav d-xl-none'>
          <NavItem className='mobile-menu me-auto'>
            <NavLink
              className='nav-menu-main menu-toggle hidden-xs is-active'
              onClick={() => setMenuVisibility(true)}
            >
              <Menu className='ficon' />
            </NavLink>
          </NavItem>
        </ul>

        <h4 className='fw-bold fs-4  h-100 pt-1'>{handlePageName()}</h4>
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  );
};

export default ThemeNavbar;
