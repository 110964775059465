import { useContext } from 'react';
import { SelectedCompanyContext } from '../context';

const useSelectedCompany = () => {
  const { selectedCompany, setSelectedCompany } = useContext(SelectedCompanyContext);

  const setCompany = (value) => {
    setSelectedCompany(value);
  };

  return { company: selectedCompany, setCompany };
};

export default useSelectedCompany;
