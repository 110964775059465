import { useContext } from 'react';
import { Network, Urls } from '../apiConfiguration';
import { showErrorMessage } from '../components';

import { CountriesContext } from '../context';
import useLoader from './useLoader';

const useCountries = () => {
  const { countries, setCountries } = useContext(CountriesContext);
  const { setLoading } = useLoader();

  const getAllCountries = async () => {
    setLoading(true);
    const response = await Network.get(Urls.getCountries);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setCountries(response.data);
  };

  return { countries, getAllCountries };
};

export default useCountries;
