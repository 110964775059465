import { Modal, ModalHeader, ModalBody, Row, Col, Label, Button, Input } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import {useAuth, useLoader, useSelectedCompany} from '../../../hooks';
import { Network, Urls } from '../../../apiConfiguration';
import { showErrorMessage, showSuccessMessage } from '../../../components';
import { DataTransformer, sumSubTaskTime,validTimeEntryCheck } from '../../../utility/Utils';

const SubTaskModal = ({
  open,
  setOpen,
  selectedTask,
  projectStatus,
  setApiCall,
  calculateTaskUsedTime,
}) => {
  const { setLoading } = useLoader();
  const { company } = useSelectedCompany();
  const [employees, setEmployees] = useState([]);
  const [timeError,setTimeError] = useState(false);
  const { user } = useAuth();

  const getEmployee = async (locationId) => {
    setLoading(true);
    console.log({locationId});
    const response = await Network.post(Urls.getEmployeeByRole,{
      role: [14,13],
      location_id: locationId,
    });
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setEmployees(DataTransformer(response.data, 'name', 'id'));
  };

  const initialValues = {
    project_id: selectedTask?.project_id,
    client_id: selectedTask?.client_id,
    employee_id: selectedTask?.editor_id,
    location_id: selectedTask?.location_id,
    zone_number: selectedTask?.zone_number,
    status: 0,
    coordinator_id: 148,
    work_code_id: selectedTask?.work_code_id,
    time: '',
    task_id: selectedTask?.id,
  };

  const timeCheck = () => {
    const time = selectedTask?.time;
    const [hours, minutes] = time.split(':').map(Number);
    let totalHours = hours;
    let totalMinutes = minutes;
    selectedTask?.sub_tasks?.forEach((subTask) => {
      const [subHours, subMinutes] = subTask.time.split(':').map(Number);
      totalHours -= subHours;
      totalMinutes -= subMinutes;
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;
    totalHours = totalHours % 24;
    const formattedHours = totalHours.toString().padStart(2, '0');
    const formattedMinutes = totalMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };
  const onSubmit = async (data) => {
    if(timeError) return
    setLoading(true);
    const response = await Network.post(Urls.createTask(company), data);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);

    showSuccessMessage('Subtask added successfully');
    setOpen(false);
    setApiCall((prev) => !prev);
  };

  const { values, handleSubmit, handleChange, handleBlur, touched, errors,setErrors } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object().shape({
      time: Yup.string().required('Time is required'),
      status: Yup.string().required('Status is required'),
      employee_id: Yup.string().required('Employee is required'),
    }),
  });

  useEffect(() => {
    getEmployee(user.office_location_id);
  }, []);

  const ErrorMessage = ({ name }) => {
    if (!touched[name]&&!errors[name]) return null;
    return <div className='text-danger fst-italic fw-bold'>{errors[name]}</div>;
  };

  const compareTimes = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;
    return totalMinutes1 > totalMinutes2;
  }


  const checkValue = (e) => {
    if(!validTimeEntryCheck(values.time)) {
      console.log('valid time');

      setErrors({"time": `Please enter time in 15 minutes interval`})
      setTimeError(true);
      return
    }
    const timeTotal = timeCheck();
    console.log({timeTotal});
    console.log({values})
    if(compareTimes(values.time, timeTotal)) {
      setTimeError(true);
      return setErrors({"time": `${values.time} cannot be greater than ${timeTotal}`})

    }
    setTimeError(false);
    // return setErrors({"time": `${values.time} some be greater than ${timeTotal}`})
    handleBlur(e);
  }
  console.log({errors});

  return (
    <Modal isOpen={open} toggle={() => setOpen(!open)}>
      <ModalHeader className='bg-white' toggle={() => setOpen(!open)}>
        Add SubTask
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Time</Label>
            <InputMask
              onBlur={(e)=> {
                checkValue(e);
              }}
              placeholder='00:00'
              mask='99:99'
              name='time'
              maskChar={':'}
              value={values.time}
              onChange={(e) => {
                const inputTime = e.target.value;
                console.log(inputTime);
                const [hours, minutes] = inputTime.split(':').map(Number);
                let convertedHours = hours;
                let convertedMinutes = minutes;

                if (minutes > 59) {
                  const extraHours = Math.floor(minutes / 60);
                  convertedHours += extraHours;
                  convertedMinutes = minutes % 60;
                }
                const formattedTime = `${convertedHours
                  .toString()
                  .padStart(2, '0')}:${convertedMinutes.toString().padStart(2, '0')}`;
                handleChange({
                  target: {
                    name: 'time',
                    value: formattedTime,
                  },
                });
              }}
            >
              {(inputProps) => <Input name='time' {...inputProps} />}
            </InputMask>
            <ErrorMessage name={'time'} />
          </Col>
          <Col className='mt-1' md={6} sm={6}>
            <Label>Employees</Label>
            <Select
              name='employee_id'
              placeholder='Employee'
              value={employees.filter((i) => i.value === values.employee_id)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'employee_id',
                    value,
                  },
                };
                handleChange(event);
              }}
              options={employees}
            />
          </Col>
          <Col className='mt-1' md={12} sm={12}>
            <Label>Status</Label>
            <Select
              name='status'
              placeholder='Status'
              value={projectStatus.filter((i) => i.value === values.status)}
              onChange={({ value }) => {
                const event = {
                  target: {
                    name: 'status',
                    value,
                  },
                };
                handleChange(event);
              }}
              options={projectStatus}
            />
            <ErrorMessage name={'status'} />
          </Col>
        </Row>
        <Button className='mt-2' color='primary' onClick={() => handleSubmit()}>
          Add SubTask
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default SubTaskModal;
