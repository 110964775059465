import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

const ConfirmationModal = ({
  showModal,
  setShowModal,
  mode,
  description,
  btnText,
  fn,
}) => {
  return (
    <Modal
      isOpen={showModal}
      toggle={() => setShowModal(!showModal)}
      className="modal-dialog-centered"
    >
      <ModalHeader
        toggle={() => setShowModal(!showModal)}
        className="bg-white"
      ></ModalHeader>
      <ModalBody>
        <h1 className="text-center text-danger">{mode}</h1>
        <h4 className="text-center mt-2">{description}</h4>
      </ModalBody>
      <div className="d-flex align-items-center justify-content-center mb-3 p-2">
        <Button
          color="danger"
          className="me-1"
          onClick={() => {
            fn();
            setShowModal(!showModal);
          }}
        >
          {btnText}
        </Button>
        <Button
          color="primary"
          className="ms-1"
          onClick={() => setShowModal(!showModal)}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
