// ** React Imports
import { useEffect } from "react";
import themeConfig from "@configs/themeConfig";

const VerticalMenuHeader = (props) => {
  const { menuCollapsed, setGroupOpen, menuHover } = props;

  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <div
            style={{
              width: "190px",
              height: "50px",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={themeConfig.app.appLogoImage}
              alt="logo"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain" }}
            />
          </div>
          <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2>
        </li>
      </ul>
    </div>
  );
};

export default VerticalMenuHeader;
