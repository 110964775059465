import { useContext } from 'react';
import { CompanyContext } from '../context';
import useLoader from './useLoader';
import { Network, Urls } from '../apiConfiguration';
import { showErrorMessage } from '../components';

const useCompanies = () => {
  const { companies, setCompanies } = useContext(CompanyContext);
  const { setLoading } = useLoader();
  const getAllCompanies = async () => {
    setLoading(true);
    const response = await Network.get(Urls.getAllCompanies);
    setLoading(false);
    if (!response.ok) return showErrorMessage(response.data.message);
    setCompanies(response.data);
  };

  return { companies, getAllCompanies };
};

export default useCompanies;
