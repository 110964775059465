import Calendar from '../assets/svgs/Calendar.svg';
import Bulb from '../assets/svgs/Bulb.svg';
import Laptop from '../assets/svgs/Laptop.svg';
import Discord from '../assets/svgs/Discord.svg';
import Thermometer from '../assets/svgs/Thermometer.svg';
import TimeMachine from '../assets/svgs/TimeMachine.svg';
import NavRoutes from './NavRoutes';

const DashboardData = [
  {
    svgImg: Calendar,
    title: 'Timesheets',
    navLink: '',
  },
  {
    svgImg: Thermometer,
    title: 'Project Hour Thermometers',
    navLink: '',
  },
  {
    svgImg: TimeMachine,
    title: 'Time Machine',
    navLink: '',
  },
  {
    svgImg: Laptop,
    title: 'Employee Database',
    navLink: '',
  },
  {
    svgImg: Bulb,
    title: 'Profit Machine',
    navLink: '',
  },
  {
    svgImg: Discord,
    title: 'Admin',
    navLink: '',
  },
  {
    svgImg: Calendar,
    title: 'Review Employee Timesheet',
    navLink: NavRoutes.reviewTimesheet,
  },
];

export default DashboardData;
