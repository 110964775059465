import { lazy } from 'react';
import BlankLayout from '@layouts/BlankLayout';

import { NavRoutes } from '../../common';
import { element } from 'prop-types';
const TrainingCenter = lazy(() => import('../../views/TrainingCenter'));
const Holidays = lazy(() => import('../../views/holidays'));
const Timesheet = lazy(() => import('../../views/staff/timesheet'));
const TodaysEntry = lazy(() => import('../../views/staff/todaysEntry'));
const Profile = lazy(() => import('../../views/staff/profile'));
const Thermometer = lazy(() => import('../../views/staff/thermometer'));
const Employee = lazy(() => import('../../views/admin/employee'));
const EditEmployee = lazy(() => import('../../views/admin/edit-employee'));
const ViewEmployee = lazy(() => import('../../views/admin/view-employee'));
const Teams = lazy(() => import('../../views/admin/teams'));
const ViewTeam = lazy(() => import('../../views/admin/teams/ViewTeam'));
const TimeMachine = lazy(() => import('../../views/timemachine'));
const ZoneReport = lazy(() => import('../../views/timemachine/reports/ZoneReport'));
const SummaryReport = lazy(() => import('../../views/timemachine/reports/ProjectSummaryReport'));
const MonthToDateReport = lazy(() => import('../../views/timemachine/reports/MonthToDateReport'));
const QaReport = lazy(() => import('../../views/timemachine/reports/QaReport'));

const Dashboard = lazy(() => import('../../views/admin/dashboard'));
const Projects = lazy(() => import('../../views/admin/project'));
const SuperAdmin = lazy(() => import('../../views/SuperAdmin/company'));
const AddCompany = lazy(() => import('../../views/SuperAdmin/company/AddCompany'));
const RolesandPermissions = lazy(() => import('../../views/rolesandpermissions'));
const AddRole = lazy(() => import('../../views/rolesandpermissions/AddRole'));
const Courses = lazy(() => import('../../views/courses'));
const Partners = lazy(() => import('../../views/partners'));
const Clients = lazy(() => import('../../views/clients'));
const Positions = lazy(() => import('../../views/positions'));
const WorkCategories = lazy(() => import('../../views/workCategories'));
const WorkCodes = lazy(() => import('../../views/workCodes'));
const WorkClassification = lazy(() => import('../../views/workClassification'));
const ProjectForm = lazy(() => import('../../views/admin/project/ProjectForm'));
const PartnerForm = lazy(() => import('../../views/partners/PartnerForm'));
const EmployeeForm = lazy(() => import('../../views/admin/employee/EmployeeForm'));
const ProjectDetail = lazy(() => import('../../views/admin/project/ProjectDetail'));
const PeriodReport = lazy(() => import('../../views/timemachine/reports/PeriodReport'));
const ProjectReport = lazy(() => import('../../views/timemachine/reports/ProjectReport'));
const ReviewTimesheet = lazy(() => import('../../views/staff/timesheet/ReviewTimesheet'));
const ProjectBudget = lazy(() => import('../../views/projectBudget'));
const AddRolesandPermission = lazy(() =>
  import('../../views/rolesandpermissions/AddRolesandPermission')
);

const TimeSheetSummary = lazy(() => import('../../views/staff/timesheet/TimeSheetSummary'));
const TimeSheetSummaryPrint = lazy(() => import('../../views/staff/timesheet/PrintTimeSheet'));

const EditProjectBudget = lazy(() => import('../../views/projectBudget/EditProjectBudget'));
const PageNotFound = lazy(() => import('../../views/PageNotFound.js'));

const ColorCode = lazy(() => import('../../views/colorCodes'));

const BillingReport = lazy(() => import('../../views/timemachine/reports/BillingReport'));

const Task = lazy(() => import('../../views/task'));
const Performance = lazy(() => import('../../views/task/performance'));

const appRoutes = [
  {
    path: NavRoutes.pageNotFound,
    element: <PageNotFound />,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: NavRoutes.newRole,
    element: <AddRolesandPermission />,
    meta: {
      action: 'read',
      resource: 'addroleandpermission',
    },
  },
  {
    path: NavRoutes.colorCode,
    element: <ColorCode />,
    meta: {
      action: 'manage',
      resource: 'colorCode',
    },
  },
  {
    path: NavRoutes.timesheet,
    element: <Timesheet />,
    meta: {
      action: 'read',
      resource: 'timeSheet',
    },
  },
  {
    path: NavRoutes.todaysEntry,
    element: <TodaysEntry />,
    meta: {
      action: 'read',
      resource: 'todaysEntry',
    },
  },
  {
    path: NavRoutes.profile,
    element: <Profile />,
    meta: {
      action: 'read',
      resource: 'profile',
    },
  },
  {
    path: NavRoutes.thermometer,
    element: <Thermometer />,
    meta: {
      action: 'read',
      resource: 'thermometer',
    },
  },
  {
    path: NavRoutes.employee,
    element: <Employee />,
    meta: {
      action: 'read',
      resource: 'employee',
    },
  },
  {
    path: NavRoutes.employeeForm,
    element: <EmployeeForm />,
    meta: {
      action: 'read',
      resource: 'employeeform',
    },
  },
  {
    path: `${NavRoutes.editEmployee}/:employeeId`,
    element: <EditEmployee />,
    meta: {
      action: 'read',
      resource: 'editEmployee',
    },
  },
  {
    path: `${NavRoutes.viewEmployee}/:employeeId`,
    element: <ViewEmployee />,
    meta: {
      action: 'read',
      resource: 'viewEmployee',
    },
  },
  {
    path: NavRoutes.teams,
    element: <Teams />,
    meta: {
      action: 'read',
      resource: 'teams',
    },
  },
  {
    path: `${NavRoutes.viewTeam}/:id`,
    element: <ViewTeam />,
    meta: {
      action: 'read',
      resource: 'viewTeam',
    },
  },
  {
    path: NavRoutes.timemachine,
    element: <TimeMachine />,
    meta: {
      action: 'read',
      resource: 'timeMachine',
    },
  },
  {
    path: NavRoutes.zoneReport,
    element: <ZoneReport />,
    meta: {
      action: 'read',
      resource: 'zoneReport',
    },
  },
  {
    path: NavRoutes.monthReport,
    element: <MonthToDateReport />,
    meta: {
      action: 'read',
      resource: 'monthReport',
    },
  },
  {
    path: NavRoutes.qaReport,
    element: <QaReport />,
    meta: {
      action: 'read',
      resource: 'qaReport',
    },
  },

  {
    path: NavRoutes.billingReport,
    element: <BillingReport />,
    meta: {
      action: 'read',
      resource: 'billing-report',
    },
  },

  {
    path: NavRoutes.projectReport,
    element: <ProjectReport />,
    meta: {
      action: 'read',
      resource: 'projectReport',
    },
  },
  {
    path: NavRoutes.summaryReport,
    element: <SummaryReport />,
    meta: {
      action: 'read',
      resource: 'summaryReport',
    },
  },
  {
    path: NavRoutes.periodReport,
    element: <PeriodReport />,
    meta: {
      action: 'read',
      resource: 'periodReport',
    },
  },
  {
    path: NavRoutes.dashboard,
    element: <Dashboard />,
    meta: {
      action: 'read',
      resource: 'dashboard',
    },
  },
  {
    path: NavRoutes.projects,
    element: <Projects />,
    meta: {
      action: 'read',
      resource: 'projects',
    },
  },
  {
    path: `${NavRoutes.projectForm}/:mode`,
    element: <ProjectForm />,
    meta: {
      action: 'read',
      resource: 'projectForm',
    },
  },
  {
    path: `${NavRoutes.projectDetail}/:id`,
    element: <ProjectDetail />,
    meta: {
      action: 'read',
      resource: 'projectDetail',
    },
  },
  {
    path: NavRoutes.rolesandpermissions,
    element: <RolesandPermissions />,
    meta: {
      action: 'read',
      resource: 'rolesandpermissions',
    },
  },
  {
    path: NavRoutes.newRole,
    element: <AddRole />,
  },
  {
    path: NavRoutes.courses,
    element: <Courses />,
    meta: {
      action: 'read',
      resource: 'courses',
    },
  },
  {
    path: NavRoutes.partners,
    element: <Partners />,
    meta: {
      action: 'read',
      resource: 'partners',
    },
  },

  {
    path: NavRoutes.clients,
    element: <Clients />,
    meta: {
      action: 'read',
      resource: 'clients',
    },
  },
  {
    path: NavRoutes.positions,
    element: <Positions />,
    meta: {
      action: 'read',
      resource: 'positions',
    },
  },
  {
    path: `${NavRoutes.partnerForm}/:mode`,
    element: <PartnerForm />,
    meta: {
      action: 'read',
      resource: 'partnerForm',
    },
  },
  {
    path: NavRoutes.workCategories,
    element: <WorkCategories />,
    meta: {
      action: 'read',
      resource: 'workCategories',
    },
  },
  {
    path: NavRoutes.workCodes,
    element: <WorkCodes />,
    meta: {
      action: 'read',
      resource: 'workCodes',
    },
  },
  {
    path: NavRoutes.workClassification,
    element: <WorkClassification />,
    meta: {
      action: 'read',
      resource: 'workClassifications',
    },
  },

  // Super Admin
  {
    path: NavRoutes.manageCompany,
    element: <SuperAdmin />,
    meta: {
      action: 'read',
      resource: 'manageCompanies',
    },
  },
  {
    path: NavRoutes.holidays,
    element: <Holidays />,
    meta: {
      action: 'read',
      resource: 'holidays',
    },
  },
  {
    path: NavRoutes.newCompany,
    element: <AddCompany />,
  },

  {
    path: NavRoutes.reviewTimesheet,
    element: <ReviewTimesheet />,
  },
  {
    path: NavRoutes.projectBudget,
    element: <ProjectBudget />,
  },
  {
    path: NavRoutes.timesheetSummary,
    element: <TimeSheetSummary />,
    meta: {
      action: 'read',
      resource: 'timesheetSummary',
    },
  },
  {
    path: NavRoutes.timeSheetPrint,
    element: <TimeSheetSummaryPrint />,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: NavRoutes.editProjectBudget,
    element: <EditProjectBudget />,
    meta: {
      action: 'read',
      resource: 'editProjectBudget',
    },
  },

  // task
  {
    path: NavRoutes.task,
    element: <Task />,
  },
  // performance
  {
    path: NavRoutes.performance,
    element: <Performance />,
  },
  {
    path: NavRoutes.trainingCenter,
    element: <TrainingCenter />,
    meta: {
      action: 'read',
      resource: 'dashboard',
    },
  },
];

export default appRoutes;
