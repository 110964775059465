import {
  AuthContext,
  CountriesContext,
  LoaderContext,
  CompanyContext,
  SelectedCompanyContext,
} from './context';
import { useState, useEffect } from 'react';
import { Loader } from './components';
import { accessRoute } from './utility/Utils';

const AppContext = ({ children }) => {
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)) {
      const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_ANATOMIC_USER_KEY));
      setToken(localStorage.getItem(process.env.REACT_APP_TOKEN_KEY));
      setCurrentUser(user);

      setSelectedCompany(user.company_id);
      accessRoute(user);
    }
  }, []);

  return (
    <>
      <Loader visible={loading} />
      <AuthContext.Provider value={{ token, currentUser, setToken, setCurrentUser }}>
        <LoaderContext.Provider value={{ setLoading, loading }}>
          <CompanyContext.Provider value={{ companies, setCompanies }}>
            <CountriesContext.Provider value={{ countries, setCountries }}>
              <SelectedCompanyContext.Provider value={{ selectedCompany, setSelectedCompany }}>
                {children}
              </SelectedCompanyContext.Provider>
            </CountriesContext.Provider>
          </CompanyContext.Provider>
        </LoaderContext.Provider>
      </AuthContext.Provider>
    </>
  );
};

export default AppContext;
