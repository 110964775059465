import {Badge, Col, Row} from "reactstrap";
import SubTaskTable from "../../task/table";
import TrainingDetails from "../TrainingDetails";
import {useState} from "react";

const TrainingCenterCard = ({ data,title, handleNavigation, NavRoutes }) => {
    const [show, setShow] = useState(false);
    return (
        <Col
            md={12}
            style={{height: '200px'}}
            className="text-center"
        >
            <h4 className="mt-2">{title}</h4>
            <Badge
                onClick={() => setShow(!show)}
                style={{width: '100px',height: '50px', margin: 'auto'}}
                color="light-primary px-2 py-1 cursor-pointer"
            >
                View
            </Badge>
            <Row>
            {show &&
                <TrainingDetails data={data} />
            }
            </Row>
        </Col>
    );
}
export default TrainingCenterCard;