export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const currency = [
  {
    id: 0,
    label: "PLN",
    value: "PLN",
  },
  {
    id: 1,
    label: "CAD",
    value: "CAD",
  },
  {
    id: 2,
    label: "USD",
    value: "USD",
  },
  {
    id: 3,
    label: "INR",
    value: "INR",
  },
  {
    id: 4,
    label: "PHP",
    value: "PHP",
  },
  {
    id: 5,
    label: "NZD",
    value: "NZD",
  },
];

export const contract = [
  { label: "Gross Contract", value: "gross" },
  { label: "Net Contract", value: "net" },
];




