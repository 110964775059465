const Arrow = () => {
  return (
    <svg width='60' height='50' xmlns='http://www.w3.org/2000/svg'>
      <line x1='10' y1='10' x2='10' y2='35' stroke='#767383' strokeWidth='1' />

      <line x1='10' y1='35' x2='50' y2='35' stroke='#767383' strokeWidth='1' />

      <polygon points='50,30 50,40 55,35' fill='#767383' />
    </svg>
  );
};

export default Arrow;
