import React from "react";
import themeConfig from "@configs/themeConfig";

const AppLogo = () => {
  return (
    <div
      style={{ height: "100px", objectFit: "contain" }}
      className="d-flex justify-content-center align-items-center w-100"
    >
      <img src={themeConfig.app.appLogoImage} alt="logo" />
    </div>
  );
};

export default AppLogo;
