import {Badge, Col, Row} from "reactstrap";
import SubTaskTable from "../../task/table";

const TrainingDetails = ({ data}) => {
    return (<Row
        md={3}
        style={{height: '200px'}}
        className="text-center border border-2 rounded m-1 py-2 mt-2 mt-md-0"
    >
        {data.video1 ?
        <Col
        md={4}>
            <video width="100%" height="100%" controls autoPlay={false}>
                <source src={data.video1} type="video/mp4"/>
            </video>
        </Col> : ''
        }
        {data?.video2 ?
            <Col
        md={4}>
        <video width="100%" height="100%" controls autoPlay={false}>
            <source src={data.video2} type="video/mp4"/>
        </video>
        </Col>
            : ''
        }
        {data?.video3 ?
        <Col
            md={4}>
        <video width="100%" height="100%" controls autoPlay={false}>
            <source src={data?.video3} type="video/mp4"/>
        </video>
        </Col>
        : ''
        }
        {data?.video4 ?
        <Col
            md={4}>
        <video width="100%" height="100%" controls autoPlay={false}>
            <source src={data?.video4} type="video/mp4"/>
        </video>
        </Col>
        : ''
        }
        {data?.video5 ?
        <Col
            md={4}>
        <video width="100%" height="100%" controls autoPlay={false}>
            <source src={data?.video5} type="video/mp4"/>
        </video>
        </Col>
        : ''
        }
    </Row>);
};
export default TrainingDetails;