// ** React Imports
import { Link } from "react-router-dom";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Third Party Components
import { Power } from "react-feather";

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

// ** Default Avatar Image
import { useAuth } from "../../../../hooks";
import ProfileImg from "../../../../assets/images/portrait/small/avatar-s-11.jpeg";

const UserDropdown = () => {
  const { Logout, user } = useAuth();

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {user?.first_name
              ? user?.first_name + " " + user?.last_name
              : user?.name}
          </span>
          <span className="user-status">{user?.role_name}</span>
        </div>
        <Avatar
          img={user?.name !== "Admin" ? user?.image : ProfileImg}
          imgHeight="40"
          imgWidth="40"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem
          onClick={() => Logout()}
          tag={Link}
          to={user?.name ? "/admin/login" : "/login"}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
