import { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';
import {Badge, Button, Card, Col, Row} from 'reactstrap';
import ColorCodeModal from './ColorCodeModal';
import { Network, Urls } from '../../apiConfiguration';
import { useLoader } from '../../hooks';
import { showErrorMessage, ConfirmationModal, showSuccessMessage } from '../../components';
import { Eye, Trash2, Edit2 } from 'react-feather';
import Laptop from "../../assets/svgs/Laptop.svg";
import {NavRoutes} from "../../common";
import Rocket from "../../assets/svgs/Rocket.svg";
import Bulb from "../../assets/svgs/Bulb.svg";
import MonthReport from "../../assets/svgs/monthToDate.svg";
import TrainingCenterCard from "./TrainingCenterCard";

const trainingCenterData = {
    employee: {
        video1: "https://backend.anatomicportal.com/Anatomic/Employee/adding_training_for_employees.mp4",
        video2: "https://backend.anatomicportal.com/Anatomic/Employee/adding_a_new_employee_to_the_system.mp4",
        video3: "https://backend.anatomicportal.com/Anatomic/Employee/assigning_roles_and_permissions_to_employees.mp4",
        video4: "https://backend.anatomicportal.com/Anatomic/Employee/changing_user_position_and_wage_information.mp4",
        video5: "https://backend.anatomicportal.com/Anatomic/Employee/reviewing_employee_time_sheets_and_timesheet_summary.mp4",
    },
    projects: {
        video1: "https://backend.anatomicportal.com/Anatomic/Projects/assigning_a_partner_to_my_project.mp4",
        video2: "https://backend.anatomicportal.com/Anatomic/Projects/how_to_revise_budget_hours_in_the_project.mp4",
        video3: "https://backend.anatomicportal.com/Anatomic/Projects/how_to_revise_project_budget_with_a_partner.mp4",
        video4: "https://backend.anatomicportal.com/Anatomic/Projects/how_to_revise_the_zone_assignment.mp4",
        video5: "https://backend.anatomicportal.com/Anatomic/Projects/how_to_view_project_budget_and_thermometer.mp4",
        video6: "https://backend.anatomicportal.com/Anatomic/Projects/real_time_project_progress.mp4",
    },
    timesheet: {
        video1: "https://backend.anatomicportal.com/Anatomic/Timesheet/how_to_fill_the_timesheet_and_edit_the_timesheet.mp4",
        video2: "https://backend.anatomicportal.com/Anatomic/Timesheet/reviewing_employee_time_sheets_and_timesheet_summary.mp4"
    },
    report: {
        video1: "https://backend.anatomicportal.com/Anatomic/Reports/exploring_the_month_to_date_report.mp4",
        video2: "https://backend.anatomicportal.com/Anatomic/Reports/exploring_the_period_report.mp4",
        video3: "https://backend.anatomicportal.com/Anatomic/Reports/exploring_the_project_report.mp4",
        video4: "https://backend.anatomicportal.com/Anatomic/Reports/exploring_the_qa_report.mp4",
        video5: "https://backend.anatomicportal.com/Anatomic/Reports/how_to_use_zone_report.mp4"
    }
};

const TrainingCenter = () => {
  return (
   <div>
        <Card>
        <h2 className='text-center pt-3'>Training Center</h2>
        <Row className='align-items-center justify-content-center'>
            <TrainingCenterCard data={trainingCenterData.employee} title='Employee' />
            <TrainingCenterCard data={trainingCenterData.projects} title='Projects' />
            <TrainingCenterCard data={trainingCenterData.timesheet} title='Timesheet' />
            <TrainingCenterCard data={trainingCenterData.report} title='Report' />
        </Row>
      </Card>
   </div>
  );
};

export default TrainingCenter;
