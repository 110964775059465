import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const Links = ({ data }) => {
  return (
    <>
      <Breadcrumb listClassName='breadcrumb-chevron mb-1'>
        {data.map((obj, index) => {
          return (
            <BreadcrumbItem key={index}>
              <Link to={obj.link} state={obj?.state}>
                {obj.title}
              </Link>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </>
  );
};

export default Links;
